window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;
    window.toastr = require('toastr');
    window.feather = require('feather-icons');
    window.moment = require('moment');

    require('bootstrap');
    require('jquery-mask-plugin');
    require('select2');

    /**
    * Bootstrap Table
    */
    require('bootstrap-table');
    require('bootstrap-table/dist/locale/bootstrap-table-pt-BR');
    require('bootstrap-table/dist/extensions/export/bootstrap-table-export.min.js');
    require('bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie.min.js');
    require('bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox.min.js');
    require('tableexport.jquery.plugin');

} catch (e) { }

window.axios = require('axios');

window.axios.defaults.headers.common = {
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    //'Authorization': 'Bearer ' + window.Laravel.apiToken,
};

window.base_url_api = document.querySelector('meta[ref="js-base_url_api"]').content
window.base_url = document.querySelector('meta[ref="js-base_url"]').content
window.i_url = document.querySelector('meta[ref="url"]').content

